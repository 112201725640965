<script setup lang="ts">
const config = useRuntimeConfig()
const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: true,
})
const { ogImage, favicon, title, description } = useHeaderInformation()

useHead({
  link: [
    { rel: 'icon', href: favicon.value, id: 'favicon' },
  ],
  meta: [
    { property: 'google-adsense-account', content: `${config.public.googleAdsenseId}` },
    { property: 'og:title', content: title.value },
    { property: 'description', content: description.value },
    { property: 'og:description', content: description.value },
    { property: 'og:image', content: `${config.public.baseUrl}/${ogImage.value}` },
    { property: 'twitter:card', content: 'summary_large_image' },
  ],
  script: [
    { src: 'https://securepubads.g.doubleclick.net/tag/js/gpt.js' },
  ],
})
</script>

<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <Title>{{ title }}</Title>
      <template v-for="link in head.link" :key="link.id">
        <Link :id="link.id" :rel="link.rel" :href="link.href" :hreflang="link.hreflang" />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>

    <Body>
      <v-app class="layout--background">
        <v-main>
          <v-container class="h-100" fluid>
            <LayoutRegisterHeader />
            <slot />
          </v-container>
        </v-main>
        <Snackbar />
      </v-app>
    </Body>
  </Html>
</template>

<style>
.layout--background {
  background: url('../assets/style/background_gradient.webp'), lightgray 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
