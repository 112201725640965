<script setup lang="ts">
import ArrowRight from '~icons/mdi/arrow-right'

const { countryLocalePath } = useCountry()
const route = useRoute()
const country = useCountry()

const logoURL = computed(() => {
  return country.isVetmazing.value ? '/logos/vetmazing_logo_red.svg' : '/logos/heiland_logo_red.svg'
})

const showRegisterButton = computed(() => {
  return !route.path.includes('/register') && !route.path.includes('/forgot-password')
})
const showLoginButton = computed(() => {
  return route.path.includes('/register') || route.path.includes('/forgot-password')
})
const homeURL = computed(() => {
  return country.isVetmazing.value ? 'https://home.vetmazing.com' : 'https://home.heiland.com'
})
</script>

<template>
  <v-row class="mt-0 mt-md-4">
    <nuxt-link :to="homeURL" class="mx-2 d-inline-flex align-center">
      <nuxt-picture
        :src="logoURL"
        width="143"
        class="mx-0 mx-md-8"
        color="primary"
      />
    </nuxt-link>

    <v-spacer />

    <router-link
      v-if="showLoginButton"
      :to="countryLocalePath('/login')"
      class="mx-2 mx-md-8 d-inline-flex align-center text-decoration-none"
    >
      <h2 class="font-weight-bold ma-0">
        {{ $t('login.label') }}
      </h2>
      <v-icon :icon="ArrowRight" size="24" color="red-600 mx-1" />
    </router-link>

    <router-link v-if="showRegisterButton" :to="countryLocalePath('/register')" class="mx-2 mx-md-8 d-inline-flex align-center text-decoration-none">
      <h2 class="font-weight-bold ma-0">
        {{ $t('register') }}
      </h2>
      <v-icon :icon="ArrowRight" size="24" color="red-600 mx-1" />
    </router-link>
  </v-row>
</template>
